import { Box, Typography } from '@material-ui/core'
import React from 'react'
import Basic from 'shared/layouts/Basic'

interface IProps { }

const DashboardInterface: React.FC<IProps> = () => {
    return <Basic title="Dashboard">
        <Box><Typography variant="h6" align="center"><b>Wellcome To RMS</b></Typography></Box>
    </Basic>
}

export default DashboardInterface