import { Box, Typography } from "@material-ui/core";
import React from "react";
import { NotfoundError, PermissionError } from "shared/errors";

interface IProps {
  error: Error;
}

const Error: React.FC<{
  code: "404" | "500" | "403" | "422" | "400" | "503";
  message: string;
}> = ({ code, message }) => {

  document.title = `${code} - ${message} - Agilearn`

  return (
    <Box mt={5}>
      <Typography
        variant="h1"
        align="center"
        color="textSecondary"
        style={{ fontSize: "12rem" }}
      >
        {code}
      </Typography>

      <Typography align="center" variant="h4">
        {message}
      </Typography>
    </Box>
  );
};

const ErrorBlock: React.FC<IProps> = ({ error }) => {
  if (error instanceof NotfoundError)
    return <Error code="404" message={error.message} />;
  if (error instanceof PermissionError)
    return <Error code="403" message={error.message} />;

  return <Error code="500" message={error.message} />;
};

export default ErrorBlock;
