import { Guard } from "interfaces";

const AuthenticationGuard: Guard = ({ authUser }, next) => {

    if (authUser === null) {
        next({ to: `/login?continue=${window.location.pathname}` })
    } else {
        next()
    }

}

export default AuthenticationGuard