import { LinearProgress } from '@material-ui/core'
import React from 'react'
import ErrorBlock from '../ErrorBlock'

interface IProps {
    checking: boolean
    error: Error | undefined
}

const RouteGuardInterface: React.FC<IProps> = ({ children, checking, error }) => {

    if (checking) return <LinearProgress />

    if (error) return <ErrorBlock error={error} />

    return <>{children}</>
}

export default RouteGuardInterface