import {
  Box,
  Button,
  Divider,
  Drawer,
  LinkProps,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import items from "./items";
import IMAGES from "shared/assets/images";

interface IProps {
  open: boolean;
  activeTab?: "vouchers" | "products" | "orders";
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  menuItem: {
    padding: 0,
    "&.Mui-selected": {
      backgroundColor: "#fff !important",
      "& a": {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    width: "100%",
    "& a": {
      borderRadius: "0px !important",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      "&:hover": {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  menuButtonTitle: {
    fontWeight: "bold",
    textTransform: "none",
  },
}));

interface IRouterLinkProps {
  to: string;
  title: string;
  selected: boolean;
  icon?: React.ReactElement;
}

const RouterLink: React.FC<IRouterLinkProps> = (props) => {
  const classes = useStyles();
  const { icon, title, to } = props;
  const renderLink = React.useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem
      selected={props.selected}
      className={classes.menuItem}
      disableGutters
      key={title}
    >
      <Button fullWidth startIcon={icon} component={renderLink}>
        <Typography className={classes.menuButtonTitle}>{title}</Typography>
      </Button>
    </ListItem>
  );
};

const BasicSidebar: React.FC<IProps> = ({ open, activeTab }) => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box
        height={60}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <img width="100%" src={IMAGES.LOGO.default} alt="logo" />
      </Box>
      <Divider />

      <Box className="items">
        <List>
          {items.map((menu, key) => (
            <RouterLink
              selected={activeTab === menu.key.toLowerCase()}
              to={menu.to}
              title={menu.label}
              icon={menu.icon}
              key={key}
            />
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default BasicSidebar;
