import { createTheme } from "@material-ui/core";

export default createTheme({
  spacing: 5,
  shape: { borderRadius: 20 },
  palette: {
    background: {
      default: "#eaeaea",
      paper: "#fff",
    },
  },
});
