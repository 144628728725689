import { createContext, useState, useCallback, useContext } from 'react'
import { Color } from '@material-ui/lab'

import { ToastType } from 'types'

export const SnackbarContext = createContext<{
  toast: ToastType
  showToastMessage: (message: string, severity?: Color) => void
  closeToast: () => void
}>({
  toast: { message: '', severity: undefined },
  showToastMessage: (message: string, severity?: Color) => {},
  closeToast: () => {}
})

export function useToast() {
  const [toast, setToast] = useState<ToastType>({
    message: '',
    severity: undefined
  })

  const showToastMessage = useCallback(
    (message: string, severity: Color = 'success') => {
      setToast({ message, severity })
    },
    []
  )
  const closeToast = useCallback(() => {
    setToast({ message: '', severity: undefined })
  }, [])

  return {
    toast,
    showToastMessage,
    closeToast
  }
}

export const useSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext)

  return snackbarContext.showToastMessage
}
