import { CircularProgress } from "@material-ui/core";
import { IRoute } from "interfaces";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import RouteContext from "shared/contexts/RouteContext";
import RouteGuard from "../RouteGuard";

interface IProps {
  routes: IRoute[];
  getPath: (path: string) => string;
}

const RouterViewInterface: React.FC<IProps> = ({ routes, getPath }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        {routes.map((route) => {
          const { component: Component, path, children = [] } = route;
          return (
            <Route
              key={path}
              strict
              exact={!children.length}
              path={getPath(path)}
            >
              <RouteContext.Provider value={children}>
                <RouteGuard route={route}>
                  <Component />
                </RouteGuard>
              </RouteContext.Provider>
            </Route>
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default RouterViewInterface;
