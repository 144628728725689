import { Guard } from "interfaces";
import { PermissionError } from "shared/errors";

const AuthenticationGuard: Guard = ({ claims }, next) => {
  if (!claims["payment:admin"] || !claims["admin"]) {
    next(new PermissionError("Permission Denied"));
  } else {
    next();
  }
};

export default AuthenticationGuard;
