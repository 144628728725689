import React from 'react'
import LoginInterface from './LoginInterface'

interface IProps { }

const LoginService: React.FC<IProps> = () => {

    return <LoginInterface />
}


export default LoginService