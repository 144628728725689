import React from "react";
import ErrorBlock from "../ErrorBlock";

class ErrorBoundary extends React.Component<{}, { error: Error | null }> {

    constructor(props: any) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.error(error.message)
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return <ErrorBlock error={this.state.error} />
        }

        return this.props.children;
    }
}

export default ErrorBoundary