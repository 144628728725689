import { Guard, IRoute } from 'interfaces'
import React from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from 'reactfire'
import { useClaims } from 'shared/hooks/Auth'
import RouteGuardInterface from './RouteGuradInterface'

interface IProps { route: IRoute }

const RouteGuardService: React.FC<IProps> = ({ route, children }) => {
    const [checking, setChecking] = useState(true)
    const [error, setError] = useState<Error | undefined>()

    const { data: user } = useUser({ suspense: true })
    const history = useHistory()
    const claims = useClaims()

    const check = useCallback((index: number, guards: Guard[]) => {

        if (index > guards.length - 1) {
            setChecking(false)
            return
        }


        const guard = guards[index]

        guard({ authUser: user, claims: claims }, (data) => {
            if (data) {
                if (data instanceof Error) {
                    setError(data)
                    setChecking(false)
                } else {
                    history.push(data.to)
                }

            } else {
                check(index + 1, guards)
            }
        })

    }, [user, history, claims])


    useEffect(() => {
        if (typeof user !== 'undefined') {
            const guards = route.guards || []
            let i = 0
            check(i, guards)
        }
    }, [check, route.guards, user])

    document.title = `${route.name} - Agilearn RMS`


    return <RouteGuardInterface error={error} checking={checking} children={children} />
}

export default RouteGuardService