import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React, { useMemo } from "react";
import UserMenu from "shared/components/UserMenu";

interface IProps {
  title: string;
  ml?: number;
  toggleSidebar?: () => void;
}

const BasicHeader: React.FC<IProps> = ({ title, ml = 0, toggleSidebar }) => {
  const theme = useTheme();

  const style = useMemo<React.CSSProperties>(
    () => ({
      marginLeft: ml,
      width: `calc(100% - ${ml}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    [ml, theme.transitions]
  );

  return (
    <AppBar style={style} position="static">
      <Toolbar>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            {!!toggleSidebar && (
              <IconButton
                onClick={toggleSidebar}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <Menu />
              </IconButton>
            )}
            <Typography variant="h5">
              <b>{title}</b>
            </Typography>
          </Box>
          <Box>
            <UserMenu />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BasicHeader;
