import { MoneyOff, Apps, Payment } from "@material-ui/icons";

const items: { label: string; icon: any; to: string; key: string }[] = [
  {
    to: "/vouchers",
    icon: <MoneyOff />,
    label: "Vouchers",
    key: "vouchers",
  },
  {
    to: "/products",
    icon: <Apps />,
    label: "Products",
    key: "products",
  },
  {
    label: "Orders",
    to: "/orders",
    icon: <Payment />,
    key: "orders",
  },
];

export default items;
