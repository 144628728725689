import "./App.css";
import { CssBaseline, ThemeProvider, LinearProgress } from "@material-ui/core";
import themes from "./shared/themes";
import routes from "routes";
import RouteContext from "shared/contexts/RouteContext";
import { BrowserRouter as Router } from "react-router-dom";
import RouterView from "shared/components/RouterView";
import { useFirebaseApp } from "reactfire";
import { preloadSDKs } from "shared/utilities/Firebase";
import { useState, Suspense } from "react";
import ErrorBoundary from "shared/components/ErrorBoundary/ErrorBoundary";
import { SnackbarContext, useToast } from "shared/contexts/SnackbarContext";
import Snackbar from "shared/components/Snackbar";
import CustomClaimsProvider from "shared/providers/CustomClaims";

function App() {
  const [loading, setLoading] = useState(true);

  const firebaseApp = useFirebaseApp();
  preloadSDKs(firebaseApp).then(() => setLoading(false));
  const toastProps = useToast();

  if (loading) return <LinearProgress />;
  return (
    <Suspense fallback={<LinearProgress />}>
      <ThemeProvider theme={themes["light"]}>
        <CssBaseline />
        <ErrorBoundary>
          <CustomClaimsProvider>
            <RouteContext.Provider value={routes}>
              <SnackbarContext.Provider value={toastProps}>
                <Snackbar />
                <Router>
                  <RouterView />
                </Router>
              </SnackbarContext.Provider>
            </RouteContext.Provider>
          </CustomClaimsProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
