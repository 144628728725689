import React from 'react'
import { Menu, MenuItem, Divider, Button, Avatar, Typography, Box } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useUser, useAuth } from 'reactfire'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase'

interface IProps { }

const UserMenu: React.FC<IProps> = (props) => {
    const auth = useAuth()
    const { data: authUser } = useUser<firebase.User>({ suspense: true })
    const history = useHistory()

    // const { authUser } = useContext(AuthUserContext)
    if (!authUser) throw Error('Something went wrong! Restricted area')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const isMenuOpen = Boolean(anchorEl)
    const menuId = 'primary-account-menu'

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleSignOut = () => {
        auth.signOut().then(() => history.push('/login'))
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
    )
    const displayName: string = authUser.displayName || authUser.email || ''

    const avatar = authUser.photoURL ? (
        <Box ml={1}><Avatar alt={displayName} src={authUser.photoURL} /></Box>
    ) : (
        <Box ml={1}>
            <Avatar>
                <AccountCircle />
            </Avatar>
        </Box>
    )
    return (
        <div>
            <Button onClick={handleProfileMenuOpen}> <Typography variant='body2' style={{ color: '#fff' }}><b>{authUser.displayName}</b></Typography> {avatar}</Button>
            {renderMenu}
        </div>
    )
}

export default UserMenu
