import { LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useUser } from 'reactfire'

export interface ICustomClaimsProps { }

export const CustomClaimsContext = React.createContext<{ [p: string]: boolean }>({})

const CustomClaimsProvider: React.FC<ICustomClaimsProps> = ({ children }) => {

    const { data: user } = useUser({ suspense: true })

    const [loading, setLoading] = useState(true)
    const [claims, setClaims] = useState<{ [p: string]: boolean }>({})

    useEffect(() => {
        if (!user) {
            setLoading(false)
            return
        }

        setLoading(true)
        user.getIdTokenResult().then((value) => {

            setClaims(value.claims)
            setLoading(false)
        })

    }, [user])


    if (loading) return <LinearProgress />


    return <CustomClaimsContext.Provider value={claims}>
        {children}
    </CustomClaimsContext.Provider>
}

export default CustomClaimsProvider