import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import firebase from 'firebase'
import { useAuth } from 'reactfire'
import { StyledFirebaseAuth } from 'react-firebaseui'

interface IProps {
}



const LoginInterface: React.FC<IProps> = () => {

    const auth = useAuth()

    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [

            firebase.auth.GoogleAuthProvider.PROVIDER_ID

        ],
        callbacks: {
            // signInSuccessWithAuthResult: () => false
        }
    }

    return <Container maxWidth="md">
        <Typography variant="h1">Đăng nhập</Typography>
        <Box display="flex" justifyContent="center" mt={10}>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </Box>
    </Container>
}


export default LoginInterface