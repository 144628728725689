import { Box } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import BasicHeader from "shared/components/BasicHeader";
import BasicSidebar from "shared/components/BasicSidebar";

interface IProps {
  activeTab?: "vouchers" | "products" | "orders";
  title: string;
}

const Basic: React.FC<IProps> = ({ children, title, activeTab }) => {
  const [openSidebar, setOpenSidebar] = useState(true);

  const handleToggleSidebar = useCallback(() => setOpenSidebar((o) => !o), []);

  return (
    <Box>
      <BasicHeader
        ml={openSidebar ? 240 : 0}
        toggleSidebar={handleToggleSidebar}
        title={title}
      />
      <BasicSidebar open={openSidebar} />
      <Box
        p={3}
        width={`calc(100% - ${openSidebar ? 240 : 0}px)`}
        style={{ transition: "0.5s", marginLeft: openSidebar ? 240 : 0 }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Basic;
