import { IRoute } from "interfaces";
import Dashboard from "modules/dashboard/views/Dashboard";
import { ProductsDefaultLayout } from "modules/products/layouts";
import {
  CreateProduct,
  EditProduct,
  ProductDetail,
  ProductsList,
} from "modules/products/views";
import { VouchersDefaultLayout } from "modules/vouchers/layouts";
import { VouchersList, CreateVouchers } from "modules/vouchers/views";
import { AuthenticationGuard, isAdminGuard } from "shared/guards";
import Login from "shared/views/Login";
import { OrderList } from "modules/orders";

const routes: IRoute[] = [
  {
    path: "/",
    component: Dashboard,
    guards: [AuthenticationGuard, isAdminGuard],
    name: "Dashboard",
  },
  { path: "/login", component: Login, name: "Login" },
  {
    path: "/vouchers",
    component: VouchersDefaultLayout,
    guards: [AuthenticationGuard, isAdminGuard],
    children: [
      {
        component: VouchersList,
        path: "",
        name: "Vouchers",
      },
      {
        component: CreateVouchers,
        path: "create",
        name: "Create vouchers",
      },
    ],
  },
  {
    path: "/products",
    component: ProductsDefaultLayout,
    guards: [AuthenticationGuard, isAdminGuard],
    children: [
      {
        component: ProductsList,
        path: "",
        name: "Products",
      },
      {
        component: CreateProduct,
        path: "create",
        name: "Create Product",
      },
      {
        component: ProductDetail,
        path: ":id",
        name: "Products",
      },
      {
        component: EditProduct,
        path: ":id/edit",
        name: "Edit Product",
      },
    ],
  },
  {
    path: "/orders",
    component: OrderList,
    guards: [AuthenticationGuard, isAdminGuard],
    name: "Orders",
  },
];

export default routes;
