import React from 'react'
import { useCallback } from 'react'
import { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import RouteContext from 'shared/contexts/RouteContext'
import RouterViewInterface from './RouterView-Interface'

interface IProps { }

const RouterViewService: React.FC<IProps> = () => {

    const routes = useContext(RouteContext)

    const matched = useRouteMatch()

    const getPath = useCallback((path: string) => {
        const isFullPath = path.startsWith('/')

        if (isFullPath) return path

        if (!path) return matched.path

        return ([...matched.path.split('/'), path].join('/'))

    }, [matched.path])

    return <RouterViewInterface getPath={getPath} routes={routes} />
}

export default RouterViewService